import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { CxMenuModule } from '@bbraun/cortex/carousel';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxProfileDropdownComponent } from '@bbraun/cortex/profile-dropdown';
import { HttpModule, HttpService } from '@bbraun/helpex/src/lib/http';
import { TranslocoModule } from '@ngneat/transloco';

import { HeaderComponent } from './components/header/header.component';

const modules = [
  TranslocoModule,
  CxHeaderModule,
  CxDialogModule,
  CxProfileDropdownComponent,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  MatButtonModule,
  MatDividerModule,
  HttpModule
];

const components = [ HeaderComponent ];

@NgModule({
  imports: [ ...modules, CommonModule, HttpClientModule, RouterModule, CxMenuModule ],
  declarations: [ ...components ],
  exports: [ ...modules, ...components ],
  providers: [ HttpService ]
})
export class SharedModule {}
