import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

import { ROUTES } from './modules/shared/constants/routes/routes.constants';

const routes: Routes = [
  {
    path: ROUTES.HOME,
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [ MsalGuard ]
  },
  {
    path: ROUTES.TERMS_OF_USE,
    loadChildren: () =>
      import('./modules/terms-of-use/terms-of-use.module').then((m) => m.TermsOfUseModule),
    canActivate: []
  },
  { path: '**', redirectTo: ROUTES.HOME }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, <any>{
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
