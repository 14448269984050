import { Language } from '../../interfaces/language/language.interface';

export const USER_LANGUAGES: Array<Language> = [
  { code: 'en', name: 'languages.en' },
  { code: 'fr', name: 'languages.fr' },
  { code: 'de', name: 'languages.de' },
  { code: 'es', name: 'languages.es' },
  { code: 'nl', name: 'languages.nl' },
  { code: 'pt', name: 'languages.pt' },
  { code: 'pl', name: 'languages.pl' },
  { code: 'it', name: 'languages.it' },
  { code: 'cs', name: 'languages.cs' }
];

export const AVAILABLE_LANGUAGES: Array<string> = [
  'en',
  'fr',
  'de',
  'es',
  'nl',
  'pt',
  'pl',
  'it',
  'cs'
];

export const DEFAULT_LANGUAGE = 'en';
