import { Injectable } from '@angular/core';

import { HashMap, TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { LOCAL_STORAGE } from '../../constants/local-storage/local-storage.constants';
import { AVAILABLE_LANGUAGES } from '../../constants/user-languages/user-languages.constants';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  constructor(private readonly translocoService: TranslocoService) {}

  init(): void {
    // Try to get user language from localStorage first => navigator.language second => transloco default
    const localStorageLanguage = localStorage.getItem(LOCAL_STORAGE.LANGUAGE);
    if (localStorageLanguage) {
      this.setActiveLang(localStorageLanguage);
    } else {
      AVAILABLE_LANGUAGES.forEach((lang: string) => {
        if (window.navigator.language.includes(lang)) {
          this.setActiveLang(lang);
          localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lang);
          return;
        }
      });
    }
  }

  setActiveLang(lang: string): void {
    // force download of .json, so we could translate strings after angular init
    this.translocoService.load(lang).pipe(take(1)).subscribe();
    this.translocoService.setActiveLang(lang);
    localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lang);
  }

  getActiveLang(): string {
    return this.translocoService.getActiveLang();
  }

  translate(value: string, params?: HashMap<unknown>, lang?: string): string {
    return this.translocoService.translate(value, params, lang);
  }

  selectTranslate(
    value: string,
    params?: HashMap<unknown>,
    lang?: string
  ): Observable<string> {
    return this.translocoService.selectTranslate(value, params, lang);
  }
}
