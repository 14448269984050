<cx-header *transloco="let t">
  <cx-header-title data-cy="header-title">{{ title() }}</cx-header-title>

  <cx-header-quickbar class="ml-sm" *ngIf="!loading()">
    <cx-profile-dropdown class="ml-sm"
            [firstname]="userData().firstName"
            [lastname]="userData().lastName"
            [email]="userData().email!"
            (logout)="openLogOutDialog()"
    ></cx-profile-dropdown>
  </cx-header-quickbar>
</cx-header>
