import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Attributes, User } from '../../interfaces/user/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private readonly httpService: HttpService) {}

  /**
   * Get a specific user
   *
   */
  getUser(): Observable<User> {
    return this.httpService
      .request('me')
      .get(`${environment.backendUrl}`);
  }

  /**
   * Update a specific user
   *
   * @param data User
   */
  updateUser(data: Attributes): Observable<User> {
    return this.httpService
      .request('me')
      .body(data)
      .put(`${environment.backendUrl}`);
  }

  /**
   * Update a specific user avatar
   *
   * @param avatar - avatar image of the user
   */
  updateAvatar(avatar: Blob): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', avatar, 'avatar.jpeg');
    return this.httpService
      .request('me/avatar')
      .body(formData)
      .post(`${environment.backendUrl}`);
  }

  /**
   * Deletes a specific user avatar
   *
   */
  deleteAvatar(): Observable<any> {
    return this.httpService
      .request('me/avatar')
      .delete(`${environment.backendUrl}`);
  }
}
